/* You can add global styles to this file, and also import other style files */

// ng-select2
@import "~@ng-select/ng-select/themes/default.theme.css";

// flatpicker
@import '~flatpickr/dist/flatpickr.css';

// Rating
.stars {
  font-size: 30px;
  color: var(--bs-gray);
}

.star {
  position: relative;
  display: inline-block;
}

.star_fill {
  color: var(--bs-gray);
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--bs-gray);
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

.halfHeart {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: red;
}

.p-button-rounded {
  border-radius: 10px !important;
}

// File Upload
.dropzone_sec {
  border: 2px dashed #e2e5e8 !important;
}

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: transparent !important;
  color: var(--bs-gray-400) !important;
}

.dropzone {
  border: 2px dashed var(--bs-border-color) !important;
}

dropzone>.dropzone.dz-wrapper {
  border: none !important;
}

// Range Slider
.ng5-slider .ng5-slider-pointer {
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  top: -8px !important;
  background-color: #5b73e8 !important;
  z-index: 3;
  border-radius: 16px;
}

.ng5-slider .ng5-slider-pointer:after {
  display: none !important;
}

.ng5-slider .ng5-slider-selection {
  background: #5b73e8 !important;
}

body[data-bs-theme=dark] {
  .dropzone {
    background: transparent;
  }

  .ng5-slider .ng5-slider-bar {
    background: var(--bs-gray-300);
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent !important;
  }

  // Select 2
  .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #253a55 !important;
    color: #8495ab !important;
  }

  .ng-select .ng-select-container {
    background-color: var(--bs-input-bg) !important;
    border: 1px solid var(--bs-input-border-color);
    color: var(--bs-body-color);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: var(--bs-gray-100);
    color: var(--bs-body-color);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: var(--bs-input-bg);
    color: var(--bs-body-color);
  }

  .ng-dropdown-panel {
    border: 1px solid var(--bs-gray-100);
  }

  // Sweet alert
  .swal2-popup {
    background: var(--bs-input-bg);
    color: var(--bs-body-color);
  }
}

// scroll bar
.simplebar-scrollbar:before {
  width: 100%;
  background: #a2adb7 !important;
}


// timeline

.hori-timeline {
  .owl-nav {

    .owl-prev,
    .owl-next {
      background-color: rgba(91, 115, 232, .25) !important;
      color: #5b73e8 !important;
      border-radius: 50%;
    }
  }
}

// table
.p-datatable .p-datatable-thead>tr>th {
  text-align: left !important;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0 !important;
  font-weight: 600;
  color: #212529;
  background: #ffffff;
  transition: box-shadow 0.15s;
}

input.ng-touched.ng-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

select.ng-touched.ng-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.235rem) center;
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

textarea.ng-touched.ng-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: top calc(0.375em + 0.235rem) right calc(0.375em + 0.235rem);
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.ng-select.ng-touched.ng-invalid .ng-select-container {
  border: 1px solid var(--bs-form-invalid-border-color) !important;
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: top calc(0.375em + 0.235rem) right calc(0.375em + 0.235rem);
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}

.ng-select.is-invalid .ng-select-container {
  border: 1px solid var(--bs-form-invalid-border-color) !important;
  padding-right: calc(1.5em + 0.94rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: top calc(0.375em + 0.235rem) right calc(0.375em + 0.235rem);
  background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}



input.ng-touched.ng-invalid+.invalid-feedback,
textarea.ng-touched.ng-invalid+.invalid-feedback,
select.ng-touched.ng-invalid+.invalid-feedback,
.ng-select.ng-touched.ng-invalid+.invalid-feedback {
  display: block;
}

.cr-p {
  cursor: pointer !important;
}

div.swal2-container div.swal2-popup {
  font-size: 0.85rem !important;
}

.validation-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: var(--bs-form-invalid-color);
}
